import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import ReactSelectAsync from 'react-select/async'
import { createUseStyles } from 'react-jss'

import FieldError from 'components/inputs/FieldError'
import FontIcon from 'components/base/FontIcon'
import Text from 'components/typography/Text'
import rgba from 'lib/rgba'
import { getProductTheme, isSurgicalNotesProduct } from 'lib/CheckProduct'
import { hideScrollbar, transitionFluid, truncate } from 'styles/mixins'

const HEIGHT_VARIANTS = {
  small: 40,
  regular: 54,
  large: 70
}

const MAX_MENU_HEIGHT = 168
const SELECT_RESET_VALUE = 'select.reset'

const customStyles = ({ colors, typography, zIndexes }) => ({
  container: base => ({
    ...base,

    width: '100%'
  }),

  control: (
    { transition, ...rest },
    { isDisabled, menuIsOpen, selectProps }
  ) => ({
    ...rest,

    ...transitionFluid('border'),
    backgroundColor: colors.light,
    borderColor: menuIsOpen ? 'transparent' : colors.greyLight,
    borderRadius: menuIsOpen ? '12px 12px 0 0' : 12,
    boxShadow: menuIsOpen
      ? `0px 8px 15px ${rgba(colors.primary, 0.05)}`
      : 'none',
    height: HEIGHT_VARIANTS[selectProps.variant],
    opacity: isDisabled && 0.5,
    paddingLeft: selectProps.variant === 'large' ? 50 : 20,
    paddingRight: selectProps.variant === 'large' ? 50 : 20,
    pointerEvents: isDisabled && 'none',

    '&:hover': {
      border: '1px solid transparent',
      boxShadow: `0px 8px 15px ${rgba(colors.primary, 0.05)}`
    }
  }),

  valueContainer: base => ({
    ...base,
    ...hideScrollbar,

    flexWrap: 'nowrap',
    overflow: 'scroll',
    padding: 0,
    width: 120
  }),

  placeholder: base => ({
    ...base,
    ...truncate,

    color: colors.grey,
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.medium
  }),

  singleValue: base => ({
    ...base,

    color: colors.dark,
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.medium
  }),

  multiValue: base => ({
    ...base,

    backgroundColor: colors.greyLight,
    borderRadius: 6,
    flexShrink: 0
  }),

  multiValueLabel: base => ({
    ...base,

    color: colors.dark,
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.medium
  }),

  multiValueRemove: () => ({
    ...transitionFluid(),

    alignItems: 'center',
    display: 'flex',
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 2,

    '&:hover': {
      cursor: 'pointer',
      color: colors.greyDark
    }
  }),

  input: base => ({
    ...base,

    margin: 0,
    padding: 0,
    fontSize: typography.fontSizeVariants.xSmall,

    '& input': {
      fontFamily: typography.fontFamilyVariants.primary,
      fontWeight: typography.fontWeightVariants.medium
    }
  }),

  indicatorsContainer: base => ({
    ...base,

    paddingLeft: 12,

    '& > *': {
      marginRight: 12
    },

    '& > *:last-child': {
      marginRight: 0
    }
  }),

  loadingIndicator: base => ({
    ...base,

    color: colors.grey
  }),

  menu: base => ({
    ...base,

    backgroundColor: colors.light,
    borderTop: `1px solid ${colors.greyLight}`,
    borderRadius: '0 0 12px 12px',
    boxShadow: `0px 8px 15px ${rgba(colors.primary, 0.05)}`,
    marginTop: -1,
    marginBottom: 0,
    zIndex: zIndexes.popup
  }),

  menuList: base => ({
    ...base,

    borderRadius: '0 0 12px 12px'
  }),

  option: (base, { selectProps, isFocused, isSelected, isDisabled }) => ({
    ...base,

    backgroundColor: isFocused ? colors.greyPale : colors.light,
    // eslint-disable-next-line no-nested-ternary
    color: isSurgicalNotesProduct()
      ? isSelected
        ? colors.secondary
        : colors.primaryDark
      : isSelected
        ? colors.secondary
        : colors.primary,
    cursor: 'pointer',
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.medium,
    padding: selectProps.variant === 'large' ? '9px 50px' : '9px 20px',
    opacity: isDisabled && 0.5
  }),

  noOptionsMessage: base => ({
    ...base,

    color: colors.grey,
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.regular
  }),

  loadingMessage: base => ({
    ...base,

    color: colors.grey,
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.regular
  })
})

const useClearIndicatorStyles = createUseStyles({
  icon: {
    cursor: 'pointer'
  }
})

function ClearIndicator({ innerProps }) {
  const classes = useClearIndicatorStyles()

  return (
    <FontIcon
      className={classes.icon}
      color="grey"
      name="close"
      size="xSmall"
      {...innerProps}
    />
  )
}

const useDropdownIndicatorStyles = createUseStyles({
  icon: ({ menuIsOpen }) => ({
    ...transitionFluid(),
    cursor: 'pointer',
    transform: menuIsOpen && 'rotate(180deg)'
  })
})

function DropdownIndicator({ innerProps, selectProps: { menuIsOpen } }) {
  const classes = useDropdownIndicatorStyles({ menuIsOpen })

  return (
    <FontIcon
      className={classes.icon}
      color={menuIsOpen ? 'dark' : 'grey'}
      name="angle-down"
      size="xSmall"
      {...innerProps}
    />
  )
}

function MultiValueRemove({ innerProps }) {
  return <FontIcon color="grey" name="close" size="xSmall" {...innerProps} />
}

const useNoOptionsMessageStyles = createUseStyles(({ colors, typography }) => ({
  noOptionsText: {
    paddingTop: 8,
    paddingBottom: 6
  },
  fixedOptionContainer: {
    color: ({ fixedOptionSelected }) =>
      // eslint-disable-next-line no-nested-ternary
      isSurgicalNotesProduct()
        ? fixedOptionSelected
          ? colors.secondary
          : colors.primaryDark
        : fixedOptionSelected
          ? colors.secondary
          : colors.primary,
    cursor: 'pointer',
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.medium,
    padding: ({ variant }) => (variant === 'large' ? '9px 50px' : '9px 20px'),
    borderBottom: `1px solid ${colors.greyLight}`
  }
}))

function NoOptionsMessage(props) {
  const {
    selectProps: {
      emptyMsg,
      inputValue,
      isSearchable,
      fixedOption,
      onChange,
      value: selectedValue,
      variant
    }
  } = props
  const fixedOptionSelected =
    fixedOption && fixedOption.value === selectedValue?.value

  const classes = useNoOptionsMessageStyles({ fixedOptionSelected, variant })

  return (
    <Fragment>
      {fixedOption && !fixedOptionSelected && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className={classes.fixedOptionContainer}
          onClick={() => onChange(fixedOption)}
        >
          <span>{fixedOption?.label}</span>
        </div>
      )}
      <Text
        align="center"
        className={classes.noOptionsText}
        color="grey"
        size="xSmall"
        weight="regular"
      >
        {(inputValue && 'No Results') ||
          emptyMsg ||
          (isSearchable ? 'Type Input Value' : 'No Options')}
      </Text>
    </Fragment>
  )
}

const useStyles = createUseStyles({
  selectContainer: props => ({
    flex: '1 1 0',
    marginBottom: !props.inline && 20,
    minWidth: 0,
    position: 'relative'
  })
})

function SelectInput({
  className,
  defaultOption,
  emptyMsg,
  id,
  inline,
  input,
  isClearable,
  isDisabled,
  isMulti,
  isSearchable,
  loadOptions,
  menuPlacement,
  meta,
  onChange,
  onInputChange,
  options,
  placeholder,
  showValidationMessage,
  value,
  variant,
  fixedOption
}) {
  const classes = useStyles({ inline })
  const [selectedOptions, setSelectedOptions] = useState(defaultOption)

  const selectedValue = value || selectedOptions

  const formatValue = val => {
    if (val?.valueLabel) {
      return {
        label: val.valueLabel,
        value: val.value
      }
    }

    return val
  }

  const handleChange = updatedOptions => {
    if (onChange) onChange(updatedOptions)
    if (input) {
      let updatedValue = ''
      if (updatedOptions !== null) {
        updatedValue = isMulti
          ? updatedOptions.map(option => option.value)
          : updatedOptions.value
      }

      input.onChange(updatedValue)
    }

    setSelectedOptions(updatedOptions)
  }

  useEffect(() => {
    if (defaultOption) {
      handleChange(defaultOption)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Input was reset from outside SelectInput
    if (input && input.value === SELECT_RESET_VALUE) {
      handleChange(null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, selectedOptions])

  const isAsync = !!loadOptions
  const SelectComponent = isAsync ? ReactSelectAsync : ReactSelect

  const touchedField = meta && (meta.touched || meta.submitFailed)
  const error = touchedField && meta.error
  const showInputValidation = touchedField && showValidationMessage

  return (
    <div className={classes.selectContainer}>
      <SelectComponent
        id={id}
        className={className}
        components={{
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: null,
          MultiValueRemove,
          NoOptionsMessage
        }}
        menuPlacement={menuPlacement}
        defaultValue={defaultOption}
        emptyMsg={emptyMsg}
        inline={inline}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={isAsync || isSearchable}
        loadOptions={loadOptions}
        maxMenuHeight={MAX_MENU_HEIGHT}
        onInputChange={onInputChange}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles(getProductTheme())}
        value={formatValue(selectedValue)}
        variant={variant}
        fixedOption={fixedOption}
        aria-label={placeholder || 'Select'}
      />
      {showInputValidation && error && <FieldError error={error} />}
    </div>
  )
}

const OptionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
})

SelectInput.propTypes = {
  className: PropTypes.string,
  defaultOption: PropTypes.oneOfType([
    OptionType,
    PropTypes.arrayOf(OptionType)
  ]),
  id: PropTypes.string,
  inline: PropTypes.bool,
  input: PropTypes.object,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  loadOptions: PropTypes.func,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.arrayOf(OptionType),
  placeholder: PropTypes.string,
  resetData: PropTypes.func,
  showValidationMessage: PropTypes.bool,
  value: PropTypes.oneOfType([OptionType, PropTypes.arrayOf(OptionType)]),
  variant: PropTypes.oneOf(['small', 'regular', 'large'])
}

SelectInput.defaultProps = {
  className: null,
  defaultOption: null,
  id: null,
  inline: false,
  input: null,
  isClearable: false,
  isDisabled: false,
  isMulti: false,
  isSearchable: false,
  loadOptions: null,
  menuPlacement: 'bottom',
  onInputChange: null,
  onChange: null,
  options: [],
  placeholder: 'Select',
  resetData: () => {},
  showValidationMessage: false,
  value: null,
  variant: 'small'
}

export { SELECT_RESET_VALUE }

export default SelectInput
